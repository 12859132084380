import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { OrdersCollection } from "../../Config/firebase-collection";
import { storage } from "../../Config/Firebase-config";
import { updateDocument } from "../../Config/middleWare";
import Button from "../Button/button";
export const UploadInput = ({ order }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [imgUrl, setImgUrl] = useState("");
  const [progress, setProgress] = useState(0);
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      height: "400px",
      width: "600px",
    },
  };

  const ImageStorage = async (root, image, folder) => {
    const uploadImage = storage.ref(`${root}/${folder}/${folder}`).put(image);
    uploadImage.on(
      "state_changed",
      (snapshot) => {
        let prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(prog);
      },
      (error) => {
        console.log(error);
        toast.error("اعد المحاولة");
      },
      () => {
        storage
          .ref(`${root}/${folder}`)
          .child(`${folder}`)
          .getDownloadURL()
          .then((url) => {
            setImgUrl(url);
          });
      }
    );
    return true;
  };
  const uploadFile = async (e) => {
    let file = e.target.files[0];
    await ImageStorage(
      "orderConfirm",
      file,
      `${order.orderId}-${Math.floor(Math.random() * 101)}`
    );

    toast.success("Upload Successful");
  };
  useEffect(() => {
    if (!imgUrl) return;
    updateImage();
    setProgress(0);
  }, [imgUrl]);
  const updateImage = async () => {
    await updateDocument(OrdersCollection, order.id, {
      imageReceipt: imgUrl,
      receive_date:dayjs(new Date()).format("YYYY-MM-DD hh:mm")
    });
    setIsOpen(false);
  };

  return (
    <>
      <Button
        style="open_upload"
        title="Upload Image"
        onclick={() => setIsOpen(true)}
      />
      <Modal
        isOpen={isOpen}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Example Modal"
      >
        <div className="Modal_header">
          <div>Upload Image</div>
          <img
            onClick={() => setIsOpen(false)}
            width="20"
            height="30"
            alt="close"
            src="/assets/close_modal.svg"
            style={{ cursor: "pointer" }}
          />
        </div>
        <div className="capture_image">
          <div>
            <input type="file" onChange={(e) => uploadFile(e)} />
          </div>
        </div>
        <progress value={progress} max="100" />
      </Modal>
    </>
  );
};
