import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { usersCollection } from "../Config/firebase-collection";
import { auth } from "../Config/Firebase-config";
import { getSingleDoc } from "../Config/middleWare";
export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(null);
  const [companySearch, setCompanySearch] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [subscribers, setSubScribers] = useState([]);
  const [pending, setPending] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        getSingleDoc(usersCollection, user.uid).then((e) => {
          setCurrentUser(e);
          setPending(false);
          setLoading(false);

        });
      } else {
        setPending(false);
        setLoading(false);
        setCurrentUser(null);
        navigate("/Login");
      }
    });
    return () => unsubscribe();
  }, []);

  if (pending) {
    return (
      <>
        <div className="loading_screen">
          <div>
            <img src="/Images/tamata_loading.gif" width="50%" />
  
            
          </div>
        </div>
      </>
    );
  }

  return (
    <AuthContext.Provider
      value={{ currentUser, loading, setLoading, setCurrentUser,subscribers,setSubScribers ,companySearch,setCompanySearch,setPaymentStatus,paymentStatus}}
    >
      {children}
    </AuthContext.Provider>
  );
};
