import React, { useContext, useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router";

import { PrivateRoute } from "../../Config/privateRoute";
import { AuthContext } from "../../Context/context";
import { Roles } from "../../Helpers/Role";
import { DashBoard } from "../DashBoard";
import { Operation } from "../Operation";
export const Home = () => {
  const { currentUser } = useContext(AuthContext);


  return (
    <div style={{ overflow: "hidden", height: "100vh" }}>
     <Routes>
        <Route
          path="/*"
          element={
            <PrivateRoute roles={[Roles.Admin, Roles.SuperVisor,Roles.Team]}>
              <DashBoard />
            </PrivateRoute>
          }
        /> 

        <Route
          path="/Operattion/*"
          element={
            <PrivateRoute roles={[Roles.Admin, Roles.Ship]}>
              <Operation />
            </PrivateRoute>
          }
        />

      </Routes>    
    </div>
  );
};
