import {
  doc,
  documentId,
  getDocs,
  query,
  setDoc,
  where,
  arrayUnion,
  limit,
  updateDoc,
  getDoc,
  deleteDoc,
  onSnapshot,
  orderBy,
} from "firebase/firestore";
import { auth } from "./Firebase-config";

export const signinFirebase = async (email, password) => {
  try {
    auth.signInWithEmailAndPassword(email, password).then((credential) => {
      return credential.user;
    });
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getDocumnets = async (collection, field, payload) => {
  if (!collection || !field) return;
  var data = [];
  try {
    const mydata = await getDocs(
      query(collection, where(field, "==", payload))
    );
    mydata.docs.forEach((doc) => {
      data.push({ ...doc.data(), id: doc.id });
    });
    return [...data];
  } catch (error) {
    console.log(error);
    return;
  }
};

export const getSingleDoc = async (collection, payload) => {
  if (!collection || !payload) return;
  var docSnap;
  try {
    var docSnap = await getDoc(doc(collection, payload));
    if (docSnap.exists()) return { ...docSnap.data(), id: docSnap.id };
  } catch (error) {
    console.log(error);
    return;
  }
};
export const subGruop = async (collection, payload) => {
  if (!collection) return;
  var data = [];
  try {
    const mydata = await getDocs(
      query(collection, where("group", "==", payload.toLowerCase()))
    );
    mydata.docs.forEach((doc) => {
      data.push({ ...doc.data(), id: doc.id });
    });
    return data;
  } catch (error) {
    console.log(error);
    return;
  }
};
export const getAllDoc = async (collection) => {
  if (!collection) return;
  var data = [];
  try {
    const mydata = await getDocs(collection);
    mydata.docs.forEach((doc) => {
      data.push({ ...doc.data(), id: doc.id });
    });
    return [...data];
  } catch (error) {
    console.log(error);
    return;
  }
};
export const OrdersDocs = async (collection) => {
  if (!collection) return;
  var data = [];
  try {
    const mydata = await getDocs(collection);
    mydata.docs.forEach((doc) => {
      data.push({ ...doc.data().orders });
    });
    return data;
  } catch (error) {
    console.log(error);
    return;
  }
};
export const getEmails = async (collection) => {
  if (!collection) return;
  var data = [];
  try {
    const mydata = await getDocs(collection);
    mydata.docs.forEach((doc) => {
      data.push(
        doc.data().email,
        `${doc.data().group}-${doc.data().employeeId}`
      );
    });
    return data;
  } catch (error) {
    console.log(error);
    return;
  }
};
export const updateDocument = async (collection, id, data) => {
  if (!collection || !id) return;
  try {
    await updateDoc(doc(collection, id), {
      ...data,
    });
  } catch (error) {
    console.log(error);
    return;
  }
};

export const addDocs = async (mainCollection, payload) => {
  if (!mainCollection || !payload) return;
  try {
    await setDoc(doc(mainCollection), payload);
  } catch (error) {
    console.log(error);
    return;
  }
};

export const setDocumnet = async (collection, id, payload) => {
  if (!collection || !id) return;
  try {
    await setDoc(doc(collection, id), {
      ...payload,
    },{merge:true});
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};
export const deleteDocument = async (collection, id) => {
  if (!collection || !id) return;
  try {
    await deleteDoc(doc(collection, id));
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const getMultiDoc = async (collection, field, operator, payload) => {
  if (!collection) return;
  var data = [];
  try {
    const mydata = await getDocs(
      query(collection, where(field, operator, payload))
    );
    mydata.docs.forEach((doc) => {
      data.push({ ...doc.data(), id: doc.id });
    });
    return [...data];
  } catch (error) {
    console.log(error);
    return;
  }
};
export const collectionListen = async(q) => {
  var myData;
  try {
    onSnapshot(q, (snapshot) => {
      myData = [];
      snapshot.docs.map((doc) => {
        myData.push({ ...doc.data(), id: doc.id });
      });
    });
    return [...myData];
  } catch (error) {
    console.log(error);
  }
};

export const LimitPagination  = async (collection, payload,limitation) => {
  if (!collection ) return;
  var data = [];
  try {
    const q = query(collection, orderBy(payload,"desc"), limit(limitation));
    const mydata = await getDocs(q);
    mydata.docs.forEach((doc) => {
      data.push({ ...doc.data(), id: doc.id });
    });
    return data;
  } catch (error) {
    console.log(error);
    return;
  }
};


export const getQueryDocs = async (
  collection,
  field,
  queryCond,
  payload,

) => {
  if (!collection || !queryCond || !field) return;
  var data = [];
  try {
    while (payload.length) {
      const batch = payload.splice(0, 10);
      const mydata = await getDocs(
        query(
          collection,
          where(field, queryCond, [...batch])
        )
      );
      mydata.docs.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id });
      });
    }
    return Promise.all(data).then((content) => content.flat());
  } catch (error) {
    console.log(error);
    return;
  }
};