import React, {useState } from "react";
import Modal from "react-modal";
import Button from "../Button/button";
import customStyles from "../../styles/ModalStyle.json";
export const ReportModalExport = ({HandelExport}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button
        color="white-gborder"
        style="button"
        id="export"
        title="Export"
        onclick={() => setIsOpen(true)}
      />
      <Modal
        isOpen={isOpen}
        ariaHideApp={false}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div>
          <div className="Modal_header">
            <div>Export Report</div>
            <img
              onClick={() => setIsOpen(false)}
              width="20"
              height="30"
              src="/assets/close_modal.svg"
              style={{ cursor: "pointer" }}
            />
          </div>
          <div>
          {/* <Button
              color="c70b44d"
              style="button"
              id="create-new-export"
              title="User Report"
              onclick={()=>HandelExport("customer")}
              /> */}
          <Button
              color="c70b44d"
              style="button"
              id="create-new-export"
              title="Orders Status Report"
              onclick={()=>HandelExport("orders")}
              />
          <Button
              color="c70b44d"
              style="button"
              id="create-new-export"
              title="Payments History"
              onclick={()=>HandelExport("Payments")}
              />
           </div>
        </div>
      </Modal>
    </>
  );
};
