import firebase from "firebase/compat/app";
import { getFirestore } from "firebase/firestore";

import "firebase/compat/auth";
import "firebase/compat/storage";
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};
// const firebaseConfig = {
//   apiKey: "AIzaSyBh-dOFdZhHaz3DfoVuBgamPTiBHWBqMNo",
//   authDomain: "bnpl-api-27a26.firebaseapp.com",
//   projectId: "bnpl-api-27a26",
//   storageBucket: "bnpl-api-27a26.appspot.com",
//   messagingSenderId: "513545731162",
//   appId: "1:513545731162:web:ac5acdfc0b81514b4bac63"
// };
const app = firebase.initializeApp(firebaseConfig);
const fbAdmin  = firebase.initializeApp(firebaseConfig,"Secondary");
export const db = getFirestore(app);
export const storage=firebase.storage();
export const auth = app.auth();
export const fbAdminAuth = fbAdmin.auth();

