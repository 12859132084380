import React, {  useState, useContext } from "react";
import { CurrencyIqFormat } from "../../../Componets/Input/CurrencyIqFormat ";
import { NavBar } from "../../Home/NavBar";
import Select from "react-select";
import { useNavigate } from "react-router";
import { AuthContext } from "../../../Context/context";
import { Late, OnTime } from "../../../Componets/UsetStatus";
import { AvailableBalance, dueNow } from "../../../Helpers/OrderStatus";
import { custom_select_country } from "../../../styles/Group_select_style";
import { GiUpgrade } from "react-icons/gi";
export const Tracking = ({ company }) => {
  let navigate = useNavigate();
  const {  subscribers ,companySearch,setCompanySearch,setPaymentStatus,paymentStatus} =
    useContext(AuthContext);
  const [search, setSearch] = useState(0);

  let data = subscribers
    ?.filter((e) => e.companyId.includes(companySearch?.companyId || ""))
    .filter((e) => e.name.toString().includes(search || ""))
    .filter((e) =>
      !paymentStatus ? e : dueNow(e.userOrders) > paymentStatus?.value
    );
  let totalAllowance = data?.reduce((a, b) => a + b.allowance || 0, 0);

  const HandelRouter = (e) => {
    navigate(`/${e.group}/${e.customerId}`, { state: { data: e } });
  };
  let totalDue = data
    .map((el) => dueNow(el.userOrders))
    .reduce((a, b) => a + b || 0, 0);

  const InstallmentStatus = [
    {
      label: "late",
      value: 1,
    },
  ];
  return (
    <>
      <NavBar setSearch={setSearch} subscriber={data} company={company} />
      <div style={{display:"flex",alignItems:"center",justifyContent:"center",color:"red",fontSize:"48px",height:"50vh"}}>
      <GiUpgrade />System Upgrade in Progress <GiUpgrade />
      </div>
      <div style={{display:"flex",alignItems:"center",justifyContent:"center",color:"red",fontSize:"48px",height:"50vh"}}>
      Please wait for new version....
      </div>
      {/* <div className="table_container">
        <div className="thead">
          <span className="small_width">#</span>
          <span>Name</span>
          <span className="select_width">
            <Select
              styles={custom_select_country()}
              className="react_select"
              placeholder="Company"
              options={company.map((e) => ({ label: e.label, value: e.label ,companyId:e.id}))}
              name="colors"
              isClearable={true}
              defaultValue={companySearch}
              onChange={(val) =>
                setCompanySearch(val ? val : null)
              }
            />
          </span>
          <span>Allowance</span>
          <span>Available Balance</span>
          <span>Due Now</span>
          <span>No. Orders</span>
          <span>
            <Select
              placeholder="Status"
              options={InstallmentStatus}
              styles={custom_select_country()}
              value={paymentStatus}
              isClearable={true}
              onChange={(val) => {
                setPaymentStatus(val);
              }}
            />
          </span>

          <span className="small_width"></span>
        </div>
        <div className="row_odd">
          {data.length != 0 ? (
            data.map((e, index) => (
              <div className="body_container" key={index * 4}>
                <span className="small_width">{index + 1}</span>
                <span>{e.name}</span>
                <span>{e.group.toUpperCase()}</span>
                <span>
                  <CurrencyIqFormat val={e.allowance} />
                </span>
                <span>
                  <CurrencyIqFormat
                    val={AvailableBalance(e.allowance, e.userOrders) || 0}
                  />
                </span>
                <span>
                  <CurrencyIqFormat val={dueNow(e.userOrders)} />
                </span>
                <span>{e.userOrders.length || 0}</span>
                <span>{dueNow(e.userOrders) > 0 ? <Late /> : <OnTime />}</span>

                <span
                  className="small_width view_button"
                  onClick={() => HandelRouter(e)}
                >
                  VIEW
                </span>
              </div>
            ))
          ) : (
            <div>NO USERS</div>
          )}
        </div>
      </div>
      <div className="total_price_bottom">
        <div>
          <p>
            <span className="">Total Allowance : </span>{" "}
            {<CurrencyIqFormat val={totalAllowance} />}
          </p>
          <p></p>
        </div>
        <div>
          <p>
            <span>Total Due Now : </span> {<CurrencyIqFormat val={totalDue} />}
          </p>
          <p></p>
        </div>
      </div> */}
    </>
  );
};
