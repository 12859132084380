import React, { useState, useContext, useEffect } from "react";
import Button from "../Button/button";
import Input from "../Input/Input";
import customStyles from "../../styles/ModalStyle.json";
import Modal from "react-modal";
import { CurrencyInput } from "../Input/CurrencyInput";
import dayjs from "dayjs";
import { AuthContext } from "../../Context/context";
import { addDays, AddMonth } from "../../Helpers/AddMonth";
import {
  CompaniesCollections,
  logsCollections,
  OrdersCollection,
} from "../../Config/firebase-collection";
import { v4 as uuidv4 } from "uuid";

import { toast } from "react-toastify";
import { getSingleDoc, setDocumnet } from "../../Config/middleWare";
export const CreateNewOrder = ({
  isOpen,
  setIsOpen,
  userData,
  totalOutStanding,
}) => {
  const { setLoading, currentUser } = useContext(AuthContext);
  const [orderId, setOrderID] = useState("");
  const [orderAmount, setOrderAmount] = useState(0);
  const [taxableAmount, setTaxableAmount] = useState(0);
  const [initPayment, setInitPayment] = useState(0);
  const [allowance] = useState(userData.allowance);
  const [company, setCompany] = useState({});
  var payments = [];
  var RestPrice = taxableAmount - initPayment;
  var outStanding = totalOutStanding - RestPrice;
  useEffect(() => {
    async function fetchGroup() {
      await getSingleDoc(CompaniesCollections, userData.companyId).then((e) => {
        setCompany(e);
      });
    }

    fetchGroup();
  }, []);
  const PaymentsDetail = (totalMonth, paymentDate) => {
    if (
      dayjs(new Date()).add(5, "day").format("YYYY-MM-DD") >
      dayjs(paymentDate).format("YYYY-MM-DD")
    ) {
      paymentDate = dayjs(AddMonth(paymentDate, 1)).format("YYYY-MM-DD");
    }
    for (let i = 0; i < totalMonth; i++) {
      payments.push({
        collectValue: 0,
        collectDate: "",
        PaymentDate: dayjs(AddMonth(paymentDate, i)).format("YYYY-MM-DD"),
        increment: i + 1,
        status: false,
        receivedStatus: "UPCOMMING",
        value:
          i == totalMonth - 1
            ? (RestPrice / 1000 -
                payments.reduce(
                  (a, b) => a + (b.collectValue ? 0 : b.value) / 1000,
                  0
                )) *
              1000
            : Math.floor(RestPrice / 1000 / totalMonth) * 1000,
      });
    }
  };
  const handelNewOrder = async () => {
    if (!orderId || taxableAmount == 0 || RestPrice < 0 || outStanding < 0) {
      alert("Invaild Input");
      return;
    }
    try {
      setLoading(true);
      PaymentsDetail(
        company.installmentMonth,
        new Date().setDate(company.Date || new Date().getDate())
      );
      console.log(payments);
      await setDocumnet(OrdersCollection, Number(orderId).toString().trim(), {
        orderId:Number(orderId).toString(),
        orderAmount: taxableAmount,
        originAmount: orderAmount,
        status: false,
        RestPrice: RestPrice,
        orderDate: dayjs(new Date()).format("YYYY-MM-DD"),
        payments,
        isPaid: false,
        paidDate: "",
        userId: userData.id,
        companyId: company.id,
        teamId: company.teamId || "",
        imageReceipt: "",
        ship: {
          status: false,
          update: "",
        },
        initPayment: {
          ...initPayment,
          collectValue: initPayment,
          status: initPayment == 0 ? false : true,
          paymentDate:
            initPayment != 0 ? dayjs(Date.now()).format("YYYY-MM-DD") : "",
          increment: 0,
        },
      });
      await setDocumnet(logsCollections, `${currentUser.name}-${uuidv4()}`, {
        createdBy: currentUser.name,
        type: "create_order",
        createdAt: dayjs(new Date()).format("YYYY-MM-DD HH:mm"),
        desc: `order create for user ${userData.name},Company Name ${userData.group},Order ID=${orderId},orderAmount =${orderAmount}`,
        value: orderAmount,
        userId: currentUser.id,
      });

      setLoading(false);
      toast.success("Successful");
      setIsOpen(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (orderAmount == 0) return;
    setTaxableAmount((r) => orderAmount + orderAmount * (company.tax / 100));
  }, [orderAmount]);
  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      ariaHideApp={false}
      contentLabel="Example Modal"
    >
      <div className="New_order">
        <div className="Modal_header">
          <div>Add New Order</div>
          <img
            onClick={() => setIsOpen(false)}
            width="20"
            height="30"
            src="/assets/close_modal.svg"
            style={{ cursor: "pointer" }}
          />
        </div>
        <div className="Modal_content">
          <div>
            <span>Order ID</span>
            <Input
              type={"text"}
              style={"input "}
              placeholder={"Order ID"}
              value={orderId}
              onchange={(e) => setOrderID(e)}
            />
          </div>
          <div>
            <span>Order Amount</span>
            <CurrencyInput
              value={Math.abs(orderAmount)}
              placeholder={"Order Amount"}
              onchange={(e) => setOrderAmount(e.floatValue)}
              style={"input "}
            />
          </div>
          <div>
            <span>Taxable amount</span>
            <p>{company?.tax}%</p>
            <CurrencyInput
              value={taxableAmount}
              placeholder={"Taxable Amount"}
              style={"input "}
            />
          </div>
          <div>
            <span>Init Payment</span>
            <CurrencyInput
              value={Math.abs(initPayment || 0)}
              style={"input "}
              onchange={(e) => setInitPayment(e.floatValue)}
            />
          </div>

          <div>
            <span>To be Paid</span>
            <CurrencyInput value={RestPrice} disabled={true} style={"input "} />
          </div>
          <div>
            <span>Total Available balance</span>
            <CurrencyInput
              value={outStanding}
              disabled={true}
              style={"input "}
            />
          </div>
          <div>
            <span>Allowance</span>
            <CurrencyInput
              value={allowance ? allowance : 0}
              disabled={true}
              style={"input "}
            />
          </div>
        </div>
        <div className="submit_container">
          <Button
            color="c70b44d"
            style="button"
            id="export"
            title="Create New Order"
            onclick={() => handelNewOrder()}
          />
        </div>
      </div>
    </Modal>
  );
};
