import { useContext, useEffect, useState } from "react";
import {CurrencyIqFormat} from "../../../Componets/Input/CurrencyIqFormat ";
import {AuthContext} from "../../../Context/context";
import { CompanyDetails } from "./CompanyDetails";
export const Companies = ({ partners, search }) => {
  const { currentUser ,subscribers} = useContext(AuthContext);
  
  const [isOpen, setIsOpen] = useState(false);
  const [company, setCompany] = useState(null);
  const allownaceUsage = (company) =>
    subscribers
      .filter((e) => e.companyId==company.id)
      .reduce((a, b) => a + b.allowance, 0);
  const totalOrders = (company) =>
    subscribers
      .filter((e) =>  e.companyId==company.id)
      .reduce((a, b) => a + b.userOrders?.length, 0);
  const totalUsers = (company) =>
    subscribers.filter((e) =>  e.companyId==company.id).length;
  const openModal = (company) => {
    setIsOpen(true);
    setCompany({
      info: company,
      users: subscribers.filter((e) => e.companyId==company.id),
    });
  };  
  return (
    <>
      <div className="table_container">
        <div className="thead">
          <span className="small_width">#</span>
          <span>Company</span>
          <span>Max Allowance</span>
          <span>Allowance Usage</span>
          <span>No. Orders</span>
          <span>Total Users</span>
          <span>Total Tax</span>
          <span>Tamata Tax</span>
          <span>Installment Month</span>
          <span>Status</span>
          <span>Special Company</span>
        </div>
        <div className="row_odd">
          {partners?.length != 0 ? (
            partners.filter((e) => e.label?.includes(search || ""))
              .map((e, index) => (
                <div
                  className="body_container"
                  key={index * 4}
                  onClick={() => {
                 openModal(e);
                  }}
                > 
                  <span className="small_width">{index + 1}</span>
                  <span>{e.label}</span>
                  <span>
                    <CurrencyIqFormat val={e.maxAllowance} />
                  </span>
                  <span>
                    <CurrencyIqFormat
                      val={allownaceUsage(e)}
                    />
                  </span>
                  <span>{totalOrders(e)}</span>
                  <span>{totalUsers(e)}</span>
                  <span>{e.tax}</span>
                  <span>{e.isSpecial?e.tax-e.personalTax:e.tax}</span>
                  <span>{e.installmentMonth }</span>

                  <span>
                    {e.maxAllowance == allownaceUsage(e.label.toLowerCase()) ? (
                      <span className="on-late">Off Limit</span>
                    ) : (
                      <span className="on-time">Active</span>
                    )}
                  </span>
                  <span>{e.isSpecial && "isSpecial"}</span>
                </div>
              ))
          ) : (
            <div>NO USERS</div>
          )}
        </div>
      </div>

      {isOpen && (
        <CompanyDetails
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          company={company}
        />
      )} 
    </>
  );
};
