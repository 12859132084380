import React, { useContext } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { CurrencyIqFormat } from "../../../Componets/Input/CurrencyIqFormat ";
import { ExportExcel } from "../../../Componets/ExportExcel";
import ToggleSwitch from "../../../Componets/ToggleSwitch";
import {
  CompaniesCollections,
  subscCollection,
} from "../../../Config/firebase-collection";
import { getMultiDoc, updateDocument } from "../../../Config/middleWare";
import { AuthContext } from "../../../Context/context";
import {updateMagentStatus} from "../../../Helpers/updateMagentStatuts";
export const CompanyDetails = ({ isOpen, setIsOpen, company }) => {
  const { setLoading } = useContext(AuthContext);
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "70%",
      height: "600px",
      overflow: "scroll",
    },
  };
  const changeStatus = async (status) => {
    var errors = [];
    try {
      setLoading(true);
      var subs = await getMultiDoc(
        subscCollection,
        "companyId",
        "==",
        company.info.id
      );
      const unresolved = subs.map(async (e, index) => {
       const myRes=await updateMagentStatus(e.email,status.target.checked ? 1 : 0,e.id)
        console.log(myRes);
        if (myRes.status) {
          await updateDocument(subscCollection, e.id, {
            active: status.target.checked,
          });
          toast.success("تم التحديث");
          setLoading(false);
          return;
        } else {
          toast.error("حدث خطا");
          errors = [...errors, e];
        }
      });
      const resolved = await Promise.all(unresolved);
      await updateDocument(CompaniesCollections, company.id, {
        auth: status.target.chacked,
      });
      if (errors.length !== 0) ExportExcel(errors);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  return (
    <>
      <Modal
        isOpen={isOpen}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Example Modal"
      >
        <div className="Modal_header">
          <div className="order_information">
            <div className="order_info">
              <span>Company Name</span>
              <div className="order_id">#{company.info.label}</div>
              <span> Company Created At{company.info.createAt}</span>
            </div>
            <ToggleSwitch
              changeStatus={changeStatus}
              status={company.auth}
              label={"auth"}
            />
          </div>
          <img
            onClick={() => setIsOpen(false)}
            width="20"
            height="30"
            alt="close"
            src="/assets/close_modal.svg"
            style={{ cursor: "pointer" }}
          />
        </div>
        <div>
          <div className="order_modal_info">
            <div>
              <span>Total Allowance Usage</span>
              <p>
                <CurrencyIqFormat
                  val={company.users.reduce((a, b) => a + b.allowance, 0) || 0}
                />
              </p>
            </div>

            <div>
              <span>Total Orders Amount</span>
              <p>
                <CurrencyIqFormat
                  val={company.users
                    .map((c) =>
                      c.userOrders?.reduce((a, b) => a + b.orderAmount, 0)
                    )
                    .reduce((a, b) => a + b, 0)}
                />
              </p>
            </div>
            <div>
              <span>Total OutStanding Now</span>
              <p>
                <CurrencyIqFormat
                  val={company.users
                    .map((c) =>
                      c.userOrders?.reduce((a, b) => a + b.RestPrice, 0)
                    )
                    .reduce((a, b) => a + b, 0)}
                />
              </p>
            </div>
            <div>
              <span>Total Profit</span>
              <p>
                <CurrencyIqFormat
                  val={company.users
                    .map((c) =>
                      c.userOrders?.reduce(
                        (a, b) =>
                          a +
                          b.originAmount *
                            (company.info.personalTax || 0 / 100),
                        0
                      )
                    )
                    .reduce((a, b) => a + b || 0, 0)}
                />
              </p>
            </div>
            <div>
              <span>Total Active Orders</span>
              <p>
                {company.users
                  .map((e) => e.userOrders?.length)
                  .reduce((a, b) => a + b, 0)}
              </p>
            </div>
          </div>
          <div className="table_container full_hw">
            <div className="thead">
              <span>Customer Name</span>
              <span>Order ID</span>
              <span>Order Amount</span>
              <span>Origin Amount</span>
              <span>Received Amount</span>
              <span>Paid At</span>
              <span></span>
            </div>
            <div className="row_even">
              {company.users.length != 0 ? (
                company.users
                  .filter((e) => e.userOrders.length != 0)
                  .map((cus, Uindex) =>
                    cus.userOrders.map((e, index) => (
                      <div
                        className="body_container hover_effect"
                        key={Uindex * Math.random() + index}
                      >
                        <span>{cus.name}</span>
                        <span>{e.orderId}</span>
                        <span>{<CurrencyIqFormat val={e.orderAmount} />}</span>
                        <span>{<CurrencyIqFormat val={e.originAmount} />}</span>
                        <span>
                  
                  

                            <CurrencyIqFormat
                              val={
                               (company.info.personalTax / 100) * e.originAmount
                              }
                            />
                 
                        </span>
                        <span>
                          {e.payments[e.payments.length - 1].PaymentDate}
                        </span>
                        <span
                          style={{ textAlign: "center" }}
                          className={`${
                            !e.isPaid ? "status-upcoming" : "on-time"
                          }`}
                        >
                          {e.status
                            ? "Late"
                            : new Date(e.PaymentDate).getTime() <
                                new Date().getTime() && !e.status
                            ? "Late"
                            : "UPComming"}
                        </span>
                      </div>
                    ))
                  )
              ) : (
                <div>NO Payment</div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
