import React from "react";
import NumberFormat from "react-number-format";

export const CurrencyInput = ({
  value = 0,
  onchange,
  style = "Number_Format",
  disabled,
}) => {
  return (
    <NumberFormat
      disabled={disabled || false}
      value={value}
      onValueChange={onchange}
      thousandSeparator={true}
      suffix={"  IQD"}
      className={style}
    />
  );
};
