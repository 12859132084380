import React, { useContext } from "react";
import { Navigate, useLocation, useNavigate } from "react-router";
import { auth } from "../Config/Firebase-config";
import { AuthContext } from "../Context/context";
import Button from "./Button/button";

export const AccessDenied = ({ state }) => {
  const { setCurrentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const handelLogout = () => {
    navigate("/Login");
    setCurrentUser(null);
    auth.signOut();
  };
  return (
    <>
      <Button
        color="cffffff"
        style="button"
        id="log-out"
        title="Log Out"
        onclick={() => handelLogout()}
      />
      <div className="Denied_page">
        <div>
          <p>Access denied</p>
          <p>You don't have permission to access this page</p>{" "}
        </div>
      </div>
    </>
  );
};
