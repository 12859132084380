import React, { useContext, useEffect, useState } from "react";

import { Route, Routes, useNavigate } from "react-router";

import { SubScriberView } from "./SubScriber";
import { ManageCompany } from "./ManageCompany";
import { Tracking } from "./Tracking";
import { AuthContext } from "../../Context/context";
import {
  getAllDoc,
  getDocumnets,
  getMultiDoc,
  getSingleDoc,
} from "../../Config/middleWare";
import {
  CompaniesCollections,
  OrdersCollection,
  subscCollection,
} from "../../Config/firebase-collection";
export const DashBoard = () => {
  const [groups, setGroups] = useState([]);
  const [company, setCompany] = useState([]);

  const { setLoading, currentUser, setSubScribers, subscribers } =
    useContext(AuthContext);
  var groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };
  const getUsers = async () => {
    var subs = [],
      orders = [],
      allGroups = [],
      allCompany = [];
    setLoading(true);
    switch (currentUser.role) {
      case "admin":
        subs = await getAllDoc(subscCollection);
        orders = await getAllDoc(OrdersCollection);
        allCompany = await getAllDoc(CompaniesCollections);
        allGroups = Object.keys(groupBy(subs, "group")).map((r) => ({
          label: r,
          value: r,
        }));
        setCompany([...allCompany]);
        setGroups(allGroups);
        break;
      case "supervisor":
        subs = await getMultiDoc(
          subscCollection,
          "companyId",
          "==",
          currentUser.id
        );
        orders = await getMultiDoc(
          OrdersCollection,
          "companyId",
          "==",
          currentUser.id
        );
        allCompany = await getSingleDoc(CompaniesCollections, currentUser.id);
        setCompany([allCompany]);
        break;
      // case "team":
        allCompany = await getDocumnets(
          CompaniesCollections,
          "teamId",
          currentUser.id
        );
        subs = await getDocumnets(subscCollection, "teamId", currentUser.id);
        orders = await getDocumnets(OrdersCollection, "teamId", currentUser.id);
        allGroups = Object.keys(groupBy(subs, "group")).map((r) => ({
          label: r,
          value: r,
        }));
        setCompany([...allCompany]);
        setGroups(allGroups);
        break;
    }
   
    subs = subs.map((e) => ({
      ...e,
      userOrders: orders.filter(
        (d) => d.userId.toLowerCase() == e.id.toLowerCase()
      ),
    }));
    setSubScribers(subs);
    setLoading(false);
  };
  useEffect(() => {
    getUsers();
  }, []);
  return (
    <Routes>
      <Route path="/" element={<Tracking company={company} />} />
      <Route path="/:name/:id/*" element={<SubScriberView />} />
      <Route
        path="ManageCompany/*"
        element={<ManageCompany partners={company} />}
      />
    </Routes>
  );
};
