export const AvailableBalance = (allowance, orders) =>
  allowance - orders?.reduce((a, b) => a + b.RestPrice, 0);

export const dueNow = (orders) => {
  return orders
    ?.map((or) =>
      or.status
        ? 0
        : or.payments
        ? or.payments
            .filter(
              (pay) =>
                new Date(pay.PaymentDate).getTime() < new Date().getTime() &&
                !pay.status
            )
            .reduce((a, b) => a + b.value, 0)
        : 0
    )
    .reduce((a, b) => a + b, 0);
};
