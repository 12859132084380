import React, { useState, useContext, useEffect } from "react";
import Button from "../../../Componets/Button/button";
import Input from "../../../Componets/Input/Input";

import customStyles from "../../../styles/ModalStyle.json";
import Modal from "react-modal";
import { CurrencyInput } from "../../../Componets/Input/CurrencyInput";
import { AuthContext } from "../../../Context/context";
import {
  CompaniesCollections,
  logsCollections,
  subscCollection,
} from "../../../Config/firebase-collection";
import { getSingleDoc, setDocumnet, updateDocument } from "../../../Config/middleWare";
import { toast } from "react-toastify";
import {increment} from "firebase/firestore";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";

export const UpdateUserInfo = ({ isOpen, setIsOpen, userData }) => {
  const { setLoading, currentUser } = useContext(AuthContext);
  const [allowance, setAllowance] = useState(userData.allowance);
  const [email, setEmail] = useState(userData.email);
  const [company, setCompany] = useState({});

  useEffect(() => {
    async function fetchGroup() {
      await getSingleDoc(CompaniesCollections, userData.companyId).then((e) => {
        console.log(e);
        setCompany(e);
      });
    }

    fetchGroup();
  }, []);

  const handelUpdateUser = async () => {
    if (company.actuallyAllowance - (allowance-userData.allowance) < 0) {
      toast.error("Reach Maximam Allowance");
      return;
    }
    if (!email || allowance < 0) {
      toast.error("Data incorrect");
      return;
    }
    setLoading(true)
    await updateDocument(subscCollection, userData.id, {
        email: email,
        allowance: allowance,
    }); 

    await updateDocument(CompaniesCollections,company.id,{
        actuallyAllowance:increment(-(allowance-userData.allowance))
    })
    await setDocumnet(logsCollections, `${currentUser.group}-${uuidv4()}`, {
        createdBy: currentUser.group,
        type: "update_users",
        createdAt: dayjs(new Date()).format("YYYY-MM-DD HH:mm"),
        desc: `update  users ${userData.name} allowance from ${userData.allowance} to ${allowance}   & email ${userData.email} to ${email}`,
        userId: currentUser.id,
    });
    setLoading(false)
    setIsOpen(false)
  };

  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      ariaHideApp={false}
      contentLabel="Example Modal"
    >
      <div className="New_order">
        <div className="Modal_header">
          <img
            onClick={() => setIsOpen(false)}
            width="20"
            height="30"
            src="/assets/close_modal.svg"
            style={{ cursor: "pointer" }}
          />
        </div>
        <div className="Modal_content">
          <div>
            <span>Email</span>
            <Input
              type={"text"}
              style={"input "}
              placeholder={"Order ID"}
              value={email}
              onchange={(e) => setEmail(e)}
            />
          </div>

          <div>
            <span>Allowance</span>
            <CurrencyInput
              style={"input "}
              value={allowance}
              onchange={(e) => setAllowance(e.floatValue)}
            />
          </div>
        </div>
        <div className="submit_container">
          <Button
            color="c70b44d"
            style="button"
            id="export"
            title="update User"
            onclick={() => handelUpdateUser()}
          />
        </div>
      </div>
    </Modal>
  );
};
