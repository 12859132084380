import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { CurrencyIqFormat } from "../../../Componets/Input/CurrencyIqFormat ";
import Input from "../../../Componets/Input/Input";
import {
  CompaniesCollections,
  CompanyRequest,
  usersCollection,
} from "../../../Config/firebase-collection";
import {
  deleteDocument,
  getDocumnets,
  updateDocument,
  setDocumnet,
} from "../../../Config/middleWare";
import { AuthContext } from "../../../Context/context";

export const PendingReq = ({ search }) => {
  const { setLoading, currentUser } = useContext(AuthContext);
  const [requsets, setRequests] = useState([]);
  const getRequests = async () => {
    setLoading(true);
    let data = await getDocumnets(CompanyRequest, "confirm", false);
    if (!data) {
      setLoading(false);
      return;
    }
    setLoading(false);
    setRequests([...data]);
  };
  useEffect(() => {
    getRequests();
  }, []);

  const DeleteReq = async (company) => {
    if (window.confirm("Are You Sure For Delete")) {
      try {
        await deleteDocument(CompanyRequest, company.uid);
        toast.success("Successful");
        setRequests((prev) => {
          prev = prev.filter((e) => e.uid !== company.uid);
          return [...prev];
        });
      } catch (error) {
        console.log(error);
        toast.error("Server Error");
      }
    }
  };
  const Accept = async (company) => {
    if (
      company.totalBenfit < 0 ||
      company.tamataBenfit < 0 ||
      Number(company.totalBenfit) < Number(company.tamataBenfit) ||
      company.installment < 1 ||
      company.startDate < 1 ||
      company.startDate > 30
    )
      return;
    try {
      setLoading(true);

      await setDocumnet(usersCollection, company.uid, {
        createBy: currentUser.name,
        email: company.email,
        auth: company.auth,
        group: company.name,
        name: company.name,
        role: company.role,
        permission: [...company.permission],
        uid: company.uid,
      });
      await setDocumnet(CompaniesCollections, company.uid, {
        maxAllowance: company.allowance,
        actuallyAllowance: company.allowance,
        teamId: company.teamId || "",
        isSpecial: company.isSpecial,
        createBy: currentUser.name,
        installmentMonth: company.installment,
        createAt: dayjs(new Date()).format("YYYY-MM-DD"),
        auth: company.auth,
        label: company.name,
        Date: company.startDate,
        value: company.name,
        tax: company.totalBenfit || 0,
        personalTax: company.isSpecial
          ? company.totalBenfit - company.tamataBenfit
          : 0,
      });
      await updateDocument(CompanyRequest, company.uid, { confirm: true });
      setRequests((prev) => {
        prev = prev.filter((e) => e.uid !== company.uid);
        return [...prev];
      });
      toast.success("Successful");
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error("Server Error");
      return;
    }
  };
  const deleteCompany=async(e)=>{
    await deleteDocument(CompanyRequest,e)
  }
  return (
    <>
      <div className="table_container">
        <div className="thead">
          <span className="small_width">#</span>
          <span className="small_width">Company</span>
          <span className="small_width">Max Allowance</span>
          <span className="small_width">Month</span>
          <span className="small_width">Payment Date</span>
          <span className="small_width">Total Benefit</span>
          <span className="small_width"> Tamata Benefit</span>
          <span className="small_width"> Person Benefit</span>
          <span className="small_width"></span>
        </div>
        <div className="row_odd">
          {requsets?.length != 0 ? (
            requsets
              .filter((e) => e.name.toLowerCase().includes(search || ""))
              .map((e, index) => (
                <div className="body_container" key={index}>
                  <span className="small_width">{index + 1}</span>
                  <span className="small_width">{e.name}</span>
                  <span className="small_width">
                    <CurrencyIqFormat val={e.allowance} />
                  </span>
                  <span className="small_width">
                    <Input
                      style={"custom_input"}
                      placeholder={"Total Month"}
                      value={e.installment || 0}
                      onchange={(i) =>
                        setRequests((prev) => {
                          prev[
                            prev.findIndex((c) => c.id == e.id)
                          ].installment = i;
                          return [...prev];
                        })
                      }
                    />
                  </span>
                  <span className="small_width">
                    <Input
                      style={"custom_input"}
                      placeholder={"Total Month"}
                      value={e.startDate || 0}
                      onchange={(i) =>
                        setRequests((prev) => {
                          prev[prev.findIndex((c) => c.id == e.id)].startDate =
                            i;
                          return [...prev];
                        })
                      }
                    />
                  </span>
                  <span className="small_width">
                    <Input
                      style={"custom_input"}
                      placeholder={"tamata"}
                      value={e.totalBenfit || 0}
                      onchange={(i) =>
                        setRequests((prev) => {
                          prev[
                            prev.findIndex((c) => c.id == e.id)
                          ].totalBenfit = i;
                          return [...prev];
                        })
                      }
                    />
                  </span>
                  <span className="small_width">
                    <Input
                      style={" custom_input"}
                      value={e.tamataBenfit || 0}
                      onchange={(i) =>
                        setRequests((prev) => {
                          prev[
                            prev.findIndex((c) => c.id == e.id)
                          ].tamataBenfit = i;
                          return [...prev];
                        })
                      }
                    />
                  </span>
                  <span className="small_width">
                    {e.totalBenfit - e.tamataBenfit || 0}
                  </span>
                  <span className="small_width">
                    <a className="accept" onClick={() => Accept(e)}>
                      ACCEPT <span className="fa fa-check"></span>
                    </a>
                  </span>
                  <span onClick={()=>deleteCompany(e.id)}>delete</span>
                </div>
              ))
          ) : (
            <div>NO REQUEST</div>
          )}
        </div>
      </div>
    </>
  );
};
