import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import "./styles/componets.scss";
import "./styles/global.scss";
import "react-toastify/dist/ReactToastify.css";
import 'react-photo-view/dist/index.css';
import 'reactjs-popup/dist/index.css';

import { BrowserRouter } from "react-router-dom";

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);
