import dayjs from "dayjs";
import React from "react";
import { useBarcode } from "react-barcodes";
import { CurrencyFormat } from "../Input/CurrencyFormat ";
import { CurrencyIqFormat } from "../Input/CurrencyIqFormat ";
export const ShippingPrint = ({ cus, order, id }) => {
  const { inputRef } = useBarcode({
    value: order.id,
    options: {
      background: "white",
      height: 50,
      width: 3,
    },
  });
  console.log(cus)
  return (
    <>
      <div
        style={{
          border: "1px solid red",
          direction: "rtl",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          fontFamily: "Cairo",
          outline:"1px SOLID"
        }}
      >
        {/* <div>
          <img
            width={"100%"}
            alt="logo"
            src="/Images/Customer Commitment-header.png"
          />
          </div> */}

        <div
          style={{
            padding: "0 40px",
            backgroundColor: "white",
            fontWeight: 600,
            fontSize: "16px",
            letterSpacing: "1px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "20px 0 ",
            }}
          >
            <div>
              <p>تعهد : </p>
            </div>
            <div className="barCode">
              <svg ref={inputRef} />
            </div>
            <div>رقم : {order.id}</div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ width: "100%" }}>
              <p>اني الموقع ادناه : {cus.name||""}</p>
              <p
                style={{
                  borderBottom: "4px dotted black ",
                  width: "40%",
                  textAlign: "center",
                  margin: "40px 0",
                }}
              ></p>
            </div>
          </div>
          <div>
            <p style={{ margin: "10px 0" }}>
              المعرف بهوية الاحوال المدنية
              <span
                style={{
                  borderBottom: "4px dotted black ",
                  width: "15%",
                  display: "inline-block",
                  margin: "0 3px",
                }}
              ></span>
              الصادرة عن
              <span
                style={{
                  borderBottom: "4px dotted black ",
                  width: "15%",
                  display: "inline-block",
                  margin: "0 3px",
                }}
              ></span>
              بتاريخ
              <span
                style={{
                  borderBottom: "4px dotted black ",
                  width: "15%",
                  display: "inline-block",
                  margin: "0 3px",
                }}
              ></span>
              .
            </p>
            <p style={{ margin: "30px 0" }}>
              اسكن في
              <span
                style={{
                  borderBottom: "4px dotted black ",
                  width: "15%",
                  display: "inline-block",
                  margin: "0 3px",
                }}
              ></span>
              م
              <span
                style={{
                  borderBottom: "4px dotted black ",
                  width: "15%",
                  display: "inline-block",
                  margin: "0 3px",
                }}
              ></span>
              ز
              <span
                style={{
                  borderBottom: "4px dotted black ",
                  width: "15%",
                  display: "inline-block",
                  margin: "0 3px",
                }}
              ></span>
              د
              <span
                style={{
                  borderBottom: "4px dotted black ",
                  width: "15%",
                  display: "inline-block",
                  margin: "0 3px",
                }}
              ></span>
              <span> التابع لشركة 
              <span
                style={{
                  borderBottom: "4px dotted black ",
                  width: "15%",
                  display: "inline-block",
                  margin: "0 5px",
                  textAlign:"center"
                }}
                
              >{cus?.group?.toUpperCase()||""}</span>
              </span>
            </p>
            <p style={{ margin: "20px 0" }}>
              أويد استلام طلبي المرقم
              <span
                style={{
                  borderBottom: "4px dotted black ",
                  width: "15%",
                  display: "inline-block",
                  margin: "10px 8px",
                  textAlign: "center",
                }}
              >
                {order.id}
              </span>
              من تطبيق طماطة بقيمة
              <span
                style={{
                  borderBottom: "4px dotted black ",
                  width: "15%",
                  display: "inline-block",
                  margin: "10px 8px",
                  textAlign: "center",
                }}
              >
                <CurrencyFormat val={order.orderAmount} />
              </span>
              د.ع وتم تسديد دفعة اولى بقمية
              <span
                style={{
                  borderBottom: "4px dotted black ",
                  width: "15%",
                  display: "inline-block",
                  margin: "10px 8px",
                  fontWeight: 800,
                  fontSize: "20px",
                  textAlign: "center",
                }}
              >
                <CurrencyFormat val={order.initPayment.collectValue} />
              </span>
              د.ع عند استلامي الطلب كاملا بدون اي ضرر او تلف. اتعهد بتسديد
              المبلغ المتبقي بذمتي كاملا خلال فترة اقصاها
              <span
                style={{
                  borderBottom: "4px dotted black ",
                  width: "15%",
                  display: "inline-block",
                  margin: "10px 8px",
                  textAlign: "center",
                }}
              >
                {order.payments.length}
              </span>
              شهر من تاريخ الطلب بـ
              <span
                style={{
                  borderBottom: "4px dotted black ",
                  width: "15%",
                  display: "inline-block",
                  margin: "10px 8px",
                  textAlign: "center",
                }}
              >
                {order.payments.length}
              </span>
              دفعة شهرية مقدار الدفعة الواحد
              <span
                style={{
                  borderBottom: "4px dotted black ",
                  width: "15%",
                  display: "inline-block",
                  margin: "10px 8px",
                  textAlign: "center",
                }}
              >
                <CurrencyFormat val={order.payments[0].value} />
              </span>
              د.ع
            </p>
          </div>
          <p>
            <span> بتاريخ لا يتعدى </span>
            <span style={{ color: "red" }}>
              
              يوم {dayjs(new Date(order.payments[0].PaymentDate)).format(
                "D"
              )}
              من كل شهر ميلادي.
            </span>
          </p>
          <p>
            <span> ابتداء من  </span>
            <span
              style={{
                borderBottom: "4px dotted black ",
                width: "15%",
                display: "inline-block",
                margin: "0 10px",
                textAlign: "center",
              }}
            >
              {order.payments[0].PaymentDate}
            </span>
            <span>و انتهاء  </span>
            <span
              style={{
                borderBottom: "4px dotted black ",
                width: "25%",
                display: "inline-block",
                margin: "0 10px",
                textAlign: "center",
              }}
            >
              {order.payments[order.payments.length - 1].PaymentDate}
            </span>
          </p>
          <p style={{ borderBottom: "2px solid black ", width: "fit-content" ,fontSize:"18px"}}>

وفي حال أي أخلال في وقت تسديد الدفعات المتفق عليها مسبقا,اتعهد في دفع المبلغ المتبقي في ذمتي كاملا دفعة واحدة بما يتعلق في هذة الصفقة            </p>


          <div style={{display:"flex",justifyContent:"space-around",alignItems:"start",marginTop:"-10px"}}>
          <div
              style={{
                border: "3px solid black",
                minHeight: "25vh",
                width: "400px",
                marginTop: "60px",
                textAlign: "center",
              }}
            >
              
            </div>
            <div style={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>

            <div
              style={{
                border: "3px solid black",
                minHeight: "200px",
                width: "300px",
                marginTop: "60px",
                textAlign: "center",
              }}
              >
              <p style={{ padding: "12px" }}>
                المبلغ الواجب دفعه عند الاستلام :
                <p>
                  <CurrencyFormat val={order.initPayment.collectValue} />
                </p>
              </p>
            </div>
         <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <p style={{display:"flex",justifyContent:"space-between",width:"350px"}}>
              <p>الاسم: </p>
              <p>التوقيع:</p>

            </p>
            <p>تاريخ الاستلام:</p>
         </div>
        
              </div>
        
          </div>
          
        </div>
  
      </div>
    </>
  );
};
