import { useContext, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { AccessDenied } from "../Componets/AccessDenied";
import { AuthContext } from "../Context/context";
export const PrivateRoute = ({ children, roles }) => {
    let location = useLocation();
    const { currentUser } = useContext(AuthContext);
    const userHasRequiredRole =roles.includes(currentUser?.role) ? true : false;
    if (!currentUser) {
      return <Navigate to="/login" state={{ from: location }} />;
    }
    if (!userHasRequiredRole) {
      return <AccessDenied state={{ from: location }} />;
    }
    return children;
  };