import { Route, Routes, useNavigate } from "react-router";
import { auth } from "../../Config/Firebase-config";
import { Ship } from "./Shipping/Ship";

export const Operation = () => {
  const navigate = useNavigate("/");
  const handelLogout = () => {
    navigate("/Login");
    // setCurrentUser(null);
    auth.signOut();
  };
  return (
    <>
      <Routes>
        <Route path="" element={<Ship />} />
      </Routes>
    </>
  );
};
