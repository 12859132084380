import React, { useContext } from "react";
import { toast } from "react-toastify";
import { subscCollection } from "../Config/firebase-collection";
import { updateDocument } from "../Config/middleWare";
import { AuthContext } from "../Context/context";
import "../styles/ToggleSwitch.css";

const ToggleSwitch = ({ status, label, changeStatus }) => {
  return (
    <div className="toggle_container">
      <div className="toggle-switch">
        <input
          type="checkbox"
          className="checkbox"
          defaultChecked={status}
          defaultValue={status}
          onChange={(e) => changeStatus(e)}
          name={label}
          id={label}
        />
        <label className="label" htmlFor={label}>
          <span className="inner" />
          <span className="switch" />
        </label>
      </div>
    </div>
  );
};

export default ToggleSwitch;
