import { useContext, useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router";
import Input from "../../../Componets/Input/Input";
import {
  CompaniesCollections,
  OrdersCollection,
  subscCollection,
} from "../../../Config/firebase-collection";
import {
  getAllDoc,
  getDocumnets,
  getSingleDoc,
} from "../../../Config/middleWare";
import { PermissionsGate } from "../../../Config/PermissionsGate";
import { PrivateRoute } from "../../../Config/privateRoute";
import { AuthContext } from "../../../Context/context";
import { Roles, UserPermissions } from "../../../Helpers/Role";
import { Companies } from "./Companies";
import {Logs} from "./Logs";
import {OrdersConfirm} from "./OrdersConfirm";
import { PendingReq } from "./PendingReq";
export const ManageCompany = ({partners}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [search, setSearch] = useState("");
 
  return (
    <>
      <div className="container">
        <div className="navbar-wrapper">
          <div className="logo-search-wrapper">
            <div className="logo">
              <img
                width="100"
                height="20"
                alt="logo"
                style={{ cursor: "pointer" }}
                src="/assets/tamata_logo.svg"
                onClick={() => navigate("/")}
              />
            </div>
            <Input
              id="search"
              style="input search"
              placeholder="Searching.... "
              type="text"
              onchange={(e) => setSearch(e)}
            />
            <div className="nav_list">
              <span
                className={
                  location.pathname.split("/").length == 2 ? "active" : ""
                }
                onClick={() => navigate("")}
              >
                Active Comp.
              </span>

              <PermissionsGate permission={UserPermissions.manage_vendor}>
              <span
                  className={
                    location.pathname.includes("PendingRequest") ? "active" : ""
                  }
                  onClick={() => navigate("PendingRequest/")}
                >
                 Pending Comp.
                </span>
                <span
                  className={
                    location.pathname.includes("Confirm_Order") ? "active" : ""
                  }
                  onClick={() => navigate("Confirm_Order/")}
                >
                   Order Confirm
                </span>
                <span
                  className={
                    location.pathname.includes("Logs") ? "active" : ""
                  }
                  onClick={() => navigate("Logs/")}
                >
                   Logs
                </span>

              </PermissionsGate>
            </div>
          </div>
        </div>
      </div>
      <Routes>
        <Route
          path=""
          element={
            <Companies
              partners={partners}
              search={search}
            />
          }
        />
        <Route
          path="PendingRequest"
          element={
            <PrivateRoute roles={[Roles.Admin]}>
              <PendingReq search={search} />
            </PrivateRoute>
          }
        />
        <Route
          path="Confirm_Order"
          element={
            <PrivateRoute roles={[Roles.Admin]}>
              <OrdersConfirm  />
            </PrivateRoute>
          }
        />
        <Route
          path="Logs"
          element={
            <PrivateRoute roles={[Roles.Admin]}>
              <Logs  />
            </PrivateRoute>
          }
        />
      </Routes>
    </>
  );
};
