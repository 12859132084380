import { onSnapshot, query, where } from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import Button from "../../../Componets/Button/button";
import { CurrencyIqFormat } from "../../../Componets/Input/CurrencyIqFormat ";
import {
  logsCollections,
  OrdersCollection,
} from "../../../Config/firebase-collection";
import {
  deleteDocument,
  setDocumnet,
  updateDocument,
} from "../../../Config/middleWare";
import { AuthContext } from "../../../Context/context";
import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";
import {AvailableBalance} from "../../../Helpers/OrderStatus";
import {AiFillAlert} from "react-icons/ai"
export const OrdersConfirm = () => {
  const [orders, setOrders] = useState([]);
  const { setLoading, subscribers, currentUser } = useContext(AuthContext);
  useEffect(() => {
    const q = query(OrdersCollection, where("status", "==", "pending"));
    const unsubscriber = onSnapshot(q, (snapshot) => {
      var myData = [];
      snapshot.docs.map((doc) => {
        myData.push({ ...doc.data(), id: doc.id });
      });
      setOrders([...myData]);
      console.log([...myData])
      setLoading(false);
    });
    return unsubscriber;
  }, []);
  const OrderStatus = async (orderId, user, orderAmount, status) => {
    if (window.confirm("Are you sure")) {
      setLoading(true);
      let res = await updateDocument(OrdersCollection, orderId, {
        status: status,
      });
      await setDocumnet(logsCollections, `${currentUser.name}${uuidv4()}`, {
        createdBy: currentUser.name,
        type: "create_order",
        createdAt: dayjs(new Date()).format("YYYY-MM-DD HH:mm"),
        desc: `order confirm for user ${user.name},company Name ${user.group},Order ID=${orderId},orderAmount =${orderAmount}`,
        userId: currentUser.id,
      });
      if (res) {
        toast.success("Done");

        setLoading(false);
        return;
      }
      toast.error("حدث خطا");
      setLoading(false);
    }
  };
  const orderDelete = async (e) => {
    setLoading(true);
    await deleteDocument(OrdersCollection, e);
    setLoading(false);
  };
  //asd
  return (
    <>
      <div className="table_container">
        <div className="thead">
          <span className="small_width">#</span>
          <span>Order ID</span>
          <span>Net Amount</span>
          <span>Init Amount</span>
          <span>User Name</span>
          <span>Company</span>
          <span>Create At</span>
          <span></span>
          <span></span>
        </div>
        <div className="row_odd">
          {orders.map((e, index) => (
            <div className="body_container" key={index * 4}>
              <span className="small_width">{index + 1}</span>
              <span>{e.id}</span>
              <span>
                <CurrencyIqFormat val={e.orderAmount} />
              </span>
              <span>
                <CurrencyIqFormat val={e.initPayment.collectValue} /> {e.initPayment.collectValue>0&&<AiFillAlert  size={"16px"} fill="red"/>}
              </span>
              <span>
                {subscribers.filter((sub) => sub.id == e.userId)[0]?.name}
              </span>
              <span>
                {subscribers.filter((sub) => sub.id == e.userId)[0]?.group}
              </span>
              <span>{e.orderDate}</span>
              <span>
                <Button
                  color={"c70b44d"}
                  title={"Confirm"}
                  style={"default"}
                  onclick={() =>
                    OrderStatus(
                      e.id,
                      subscribers.filter((sub) => sub.id == e.userId)[0],
                      e.orderAmount,
                      false
                    )
                  }
                />
              </span>
              <span>
                <Button
                  color={"ceb0028"}
                  title={"Delete"}
                  style={"default"}
                  onclick={() => orderDelete(e.id)}
                />
              </span>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
