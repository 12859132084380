export const AddMonth = (date, months) => {
    var originDate = new Date(date);
    var d = originDate.getDate();
  
    originDate.setMonth(originDate.getMonth() + months);
    if (originDate.getDate() != d) {
      originDate.setDate(0);
    }
    return originDate;
  };
  
  export const addDays=(date, days)=> {
    const copy = new Date(Number(date))
    copy.setDate(date.getDate() + days)
    return copy
  }