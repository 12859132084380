import { useContext, useEffect, useState } from "react";
import { logsCollections } from "../../../Config/firebase-collection";
import { getAllDoc, LimitPagination } from "../../../Config/middleWare";
import { v4 as uuidv4 } from "uuid";
import {AuthContext} from "../../../Context/context";

export const Logs = () => {
  const [counter, setCounter] = useState(300);
  const [logsData, setLogsData] = useState([]);
const {setLoading}=useContext(AuthContext)
  const getLogs = async () => {
    setLoading(true)
    let data = await LimitPagination(logsCollections, "createdAt", counter);
    setLogsData(data);
    setLoading(false)
  };
  useEffect(() => {
    getLogs();
  }, [counter]);
  return (
    <>
      <div className="table_container" style={{ fontSize: "16px" }}>
        <div className="thead">
          <span style={{ textAlign: "left" }} className="small_width">
            #
          </span>
          <span className="small_width" style={{ textAlign: "left" }}>
            User
          </span>
          <span className="small_width" style={{ textAlign: "left" }}>
            Activity
          </span>
          <span style={{ textAlign: "left" }}>Description</span>
          <span className="small_width" style={{ textAlign: "left" }}>
            Time
          </span>
        </div>
        <div className="row_odd" style={{}}>
          {logsData.map((e, index) => (
            <div className="body_container body_style" key={index * 4}>
              <span className="small_width" style={{ textAlign: "left" }}>
                {index + 1}
              </span>
              <span className="small_width" style={{ textAlign: "left" }}>
                {e.createdBy}
              </span>
              <span className="small_width" style={{ textAlign: "left" }}>
                {e.type}
              </span>
              <span style={{ textAlign: "left" }}>{e.desc}</span>
              <span className="small_width" style={{ textAlign: "left" }}>
                {e.createdAt}
              </span>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
