import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { CreateNewOrder } from "../../../Componets/Modal/CreateNewOrder";
import {
  OrdersCollection,
  subscCollection,
} from "../../../Config/firebase-collection";
import { PermissionsGate } from "../../../Config/PermissionsGate";
import { UserPermissions } from "../../../Helpers/Role";
import { OrderCard } from "./OrderCard";
import { UserInfo } from "./UserInfo";
import { onSnapshot, query, where } from "firebase/firestore";
import Button from "../../../Componets/Button/button";
import ToggleSwitch from "../../../Componets/ToggleSwitch";
import { updateDocument } from "../../../Config/middleWare";
import { toast } from "react-toastify";
import { AuthContext } from "../../../Context/context";
import Popup from "reactjs-popup";

export const SubScriberView = () => {
  const { state } = useLocation();
  const [userData, setUserData] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const { setLoading } = useContext(AuthContext);

  const totalOutStanding =
    userData.allowance -
    userData.userOrders?.reduce((a, b) => a + b.RestPrice || 0, 0);
  const handelopenMoal = () => {
    setIsOpen(true);
  };
  useEffect(() => {
    if (!state) {
      navigate("/");
      return;
    }
    const q = query(OrdersCollection, where("userId", "==", state?.data.id));
    const unsubscriber = onSnapshot(q, (snapshot) => {
      var myData = [];
      snapshot.docs.map((doc) => {
        myData.push({ ...doc.data(), id: doc.id });
      });
      setUserData({
        ...state.data,
        userOrders: [...myData],
      });
    });
    return unsubscriber;
  }, []);
  const updateStatus = async (status) => {
    console.log("done")
    try {
      setLoading(true);
      setUserData((r) => ({ ...r, active: status }));

      var raw = JSON.stringify({
        email: userData.email,
        isBnpl: status ? 1 : 0,
        bnplCustomerId: userData.id,
      });
      var requestOptions = {
        method: "POST",
        headers: {
          TOKEN: "533b38d7a41f551f4111283c10102c4418cdf",
          "Content-Type": "application/json",
        },
        body: raw,
        redirect: "follow",
      };
      const response = await fetch(
        "https://bnpl-api.herokuapp.com/tamata_bnpl/updateStatus",
        requestOptions
      );
      const myRes = await response.json();
      if (myRes.status) {
        await updateDocument(subscCollection, userData.id, {
          active: status,
        });
        toast.success("تم التحديث");
        setLoading(false);
        return;
      }
      toast.error("حدث خطا");
      setLoading(false);
      console.log(myRes);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      <div>
        <div className="navbar-wrapper">
          <div className="navbar-left">
            <img
              width="80"
              height="30"
              alt="logo"
              src="/assets/arrow-left-line.svg"
              onClick={() => navigate("/")}
            />
            <div className="text-sm">Home / </div>
            <div className="text-md">
              {state?.data.name + " ( " + state?.data.id + " ) "}
            </div>
          </div>
          <div
            className="flex"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div onClick={()=>updateStatus(!userData?.active)} style={{fontSize:"18px",borderBottom:"1px solid blue",fontWeight:"600",color:"blue",cursor:"pointer"}}>
              {userData.active?"Disable":"Enable"}
            </div>
            {/* <ToggleSwitch
              status={userData.active}
              label={"auth"}
              changeStatus={updateStatus}
            /> */}

            <PermissionsGate permission={UserPermissions.add_orders}>
              <Button
                color="c70b44d"
                style="button"
                id="export"
                title="Create New Order"
                onclick={() => {
                  handelopenMoal();
                }}
              />
            </PermissionsGate>
          </div>
        </div>
        <div className="body-continer id-orders">
          {userData && <UserInfo userData={userData} />}
          <div className="status_orders">
            <div className=" ordesr_wrapper"></div>
            <div className="scroll orders">
              {userData.userOrders ? (
                userData.userOrders.map((e, index) => (
                  <OrderCard order={e} key={index} />
                ))
              ) : (
                <div>No orders</div>
              )}

              <div style={{ marginBottom: "490px" }}></div>
            </div>
          </div>
          {isOpen && (
            <CreateNewOrder
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              userData={userData}
              totalOutStanding={totalOutStanding}
            />
          )}
        </div>
      </div>
    </>
  );
};
