export const MonthReport = (type, subscriber, company = []) => {
  console.log(type);
  console.log(1);
  const DueNow = (order) => {
    return order.payments
      .filter(
        (r) =>
          new Date(r.PaymentDate).getTime() < new Date().getTime() && !r.status
      )
      .reduce((a, b) => a + b.value, 0);
  };

  const AvailableBalance = (allowance, orders) =>
    allowance - orders.reduce((a, b) => a + b.RestPrice, 0);

  const PaymentPaid = (order) =>
    order.payments
      ? order.payments.filter((e) => e.status).length
      : 0 + (order.PaymentPaid.status ? 1 : 0);

  const GetOrders = () => {
    console.log("done");
    var report = [];
    subscriber.map((sub) => {
      sub.userOrders.map((e) => {
        report.push({
          Date: e.orderDate,
          receive_date: e.receive_date||"",
          orderId: e.id,
          Name: sub.name,
          Eamil: sub.email,
          group: sub.group,
          originPrice: e.originAmount,
          Price: e.orderAmount,
          isSpecial: company.length == 0
          ? ""
          : company.filter((d) => d.id == sub.companyId)[0].isSpecial,
          totalPaid: e.orderAmount - e.RestPrice,
          outStanding: e.RestPrice,
          startDate:
            company.length == 0
              ? ""
              : company.filter((d) => d.id == sub.companyId)[0].Date,
          lastpaymentAmount:
            e.payments.filter((pay) => pay.status).length !== 0
              ? e.payments.filter((pay) => pay.status).slice(-1)[0].collectValue
              : 0,
          lastpaymentDate:
            e.payments.filter((pay) => pay.status).length !== 0
              ? e.payments.filter((pay) => pay.status).slice(-1)[0].collectDate
              : "-",
          nextPayment: e.payments.filter((pay) => !pay.status)[0]?.value || 0,

          insallments:
            company.length == 0
              ? ""
              : company.filter((d) => d.id == sub.companyId)[0]
                  .installmentMonth,
          tax:
            company.length == 0
              ? ""
              : company.filter((d) => d.id == sub.companyId)[0].tax,
              initPayment:e.initPayment.collectValue||0,
          DueNow: DueNow(e) || 0,
          isDeliverd:e.imageReceipt?"Deliverd":"Not Deliverd",
          employeeId:sub.employeeId ||""

        });
      });
    });
    return report;
  };
  const GetPayments = () => {
    console.log("done");
    var report = [];
    console.log(subscriber);
    subscriber.map((sub) => {
      sub.userOrders.map((e) => {
        e.payments.map((pay, index) => {
          report.push({
            Date: e.orderDate,
            orderId: e.orderId,
            Name: sub.name,
            Eamil: sub.email,
            group: sub.group,
            originPrice: e.originAmount,
            Price: e.orderAmount,
            totalPaid: e.orderAmount - e.RestPrice,
            outStanding: e.RestPrice,
            payId: pay.increment,
            PaymentDate: pay.PaymentDate,
            collectDate: pay.collectDate,
            collectValue:
              index == 0
                ? (( pay.status?pay.collectValue:pay.value) + e.initPayment.collectValue || 0)
                : pay.status
                ? pay.collectValue
                : pay.value,
            paymentPaid: pay.status,

            DueNow: DueNow(e) || 0,
          });
        });
        // report.push({
        //   Date: e.orderDate,
        //   orderId: e.orderId,
        //   Name: sub.name,
        //   group: sub.group,
        //   originPrice: e.originAmount,
        //   Price: e.orderAmount,
        //   totalPaid: e.orderAmount - e.RestPrice,
        //   outStanding: e.RestPrice,

        //   nextPayment: e.payments.filter((pay) => !pay.status)[0]?.value || 0,

        // insallments:
        //   company.length == 0
        //     ? ""
        //     : company.filter((d) => d.id == sub.companyId)[0]
        //         .installmentMonth,
        // tax:
        //   company.length == 0
        //     ? ""
        //     : company.filter((d) => d.id == sub.companyId)[0].tax,
        // DueNow: DueNow(e) || 0,
        // });
      });
    });
    return report;
  };
  const GetCustomer = () => {
    var report = [];
    subscriber.map((sub) => {
      report.push({
        Name: sub.name,
        Group: sub.group,
        Eamil: sub.email,
        allowance: sub.allowance,
        No_Order: sub.userOrders.length || 0,
        AvailableBalance: AvailableBalance(sub.allowance, sub.userOrders),
        EmployeeId: sub.employeeId,
      });
    });
    return report;
  };
  switch (type) {
    case "customer":
      return GetCustomer();
      break;
    case "orders":
      return GetOrders();
      break;
    case "Payments":
      return GetPayments();
      break;
  }
};
