import React, { useContext } from "react";
import Input from "../../Componets/Input/Input";
import Button from "../../Componets/Button/button";
import { AuthContext } from "../../Context/context";
import { useNavigate } from "react-router";
import { ExportCSV } from "../../Componets/ExportCSV";
import { MonthReport } from "../../Helpers/MonthReport";
import { ReportModalExport } from "../../Componets/Modal/ReportModalExport";
import { ImportPayment } from "../../Componets/Modal/ImportPayment";
import { auth } from "../../Config/Firebase-config";
import { PermissionsGate } from "../../Config/PermissionsGate";
import { UserPermissions } from "../../Helpers/Role";
import { ImportUsers } from "../../Componets/Modal/ImportUsers";
import { CreateCompany } from "../../Componets/Modal/CreateCompany";
import { CreateTeam } from "../../Componets/Modal/CreateTeam";
import {ExportExcel} from "../../Componets/ExportExcel";
export const NavBar = ({ setSearch, subscriber, company }) => {
  const navigate = useNavigate();
  const { currentUser, setLoading, setCurrentUser } = useContext(AuthContext);
  const HandelExport = async (e) => {
    try {
      setLoading(true);
      ExportExcel(MonthReport(e, subscriber, company), currentUser.group);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handelLogout = () => {
    navigate("/Login");
    setCurrentUser(null);
    auth.signOut();
  };

  return (
    <div className="container">
      <div className="navbar-wrapper">
        <div className="logo-search-wrapper">
          <div className="logo">
            <img
              width="100"
              height="20"
              alt="logo"
              src="/assets/tamata_logo.svg"
            />
          </div>
          <Input
            id="search"
            style="input search"
            placeholder="Searching.... "
            type="text"
            onchange={(e) => setSearch(e)}
          />
          <ReportModalExport HandelExport={HandelExport} />
          {/* {
            <PermissionsGate permission={UserPermissions.import_data}>
              <div className="dropdown">
                <button className="dropbtn">Import </button>
                <div className="dropdown-content">

                  <PermissionsGate permission={UserPermissions.add_teams}>
                    <div>
                      <CreateTeam />
                    </div>
                  </PermissionsGate>

                  <PermissionsGate permission={UserPermissions.add_group}>
                    <div>
                      <CreateCompany />
                    </div>
                  </PermissionsGate>

                  <PermissionsGate permission={UserPermissions.add_users}>
                    <div>
                      <ImportUsers />
                    </div>
                  </PermissionsGate>
                  <PermissionsGate permission={UserPermissions.add_payments}>
                    <div>
                      <ImportPayment />
                    </div>
                  </PermissionsGate>
                </div>
              </div>
            </PermissionsGate>
          } */}

          <PermissionsGate permission={UserPermissions.import_data}>
            <p className="delivery" onClick={() => navigate("/ManageCompany")}>
              {!currentUser.role.includes("supervisor")
                ? "Manage Company"
                : currentUser.group}
            </p>
          </PermissionsGate>
        </div>
        <div className="button-wrapper">
          <Button
            color="cffffff"
            style="button"
            id="log-out"
            title="Log Out"
            onclick={() => handelLogout()}
          ></Button>
        </div>
      </div>
    </div>
  );
};
