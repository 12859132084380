import dayjs from "dayjs";
import { doc, onSnapshot, setDoc, updateDoc } from "firebase/firestore";
import React, { useContext, useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router";
import ReactToPrint from "react-to-print";
import { toast } from "react-toastify";
import Button from "../../../Componets/Button/button";
import { PrintButton } from "../../../Componets/Button/PrintButton";
import Input from "../../../Componets/Input/Input";
import { ShippingPrint } from "../../../Componets/Print/ShippingPrint";
import {
  OrdersCollection,
  subscCollection,
} from "../../../Config/firebase-collection";
import { auth } from "../../../Config/Firebase-config";
import { getAllDoc, getSingleDoc } from "../../../Config/middleWare";
import { AuthContext } from "../../../Context/context";
import "./ship.scss";

export const Ship = () => {
  const navigate = useNavigate();
  const { currentUser, setLoading } = useContext(AuthContext);
  const [customer, setCustomer] = useState({});
  const [order, setOrder] = useState(null);
  const [search, setSearch] = useState("");
  const refComp = useRef();

  const handelLogout = () => {
    navigate("/Login");
    auth.signOut();
  };


  const Print = () => {
    return refComp.current;
  };
  const HandelSearch = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(search)
    var data = await getSingleDoc(OrdersCollection, search.toString());
    console.log(data)
    if (!data) {
      toast.error("لا يوجد اوردر");
      setLoading(false);
      return;
    }
    var user = await getSingleDoc(subscCollection, data.userId);
    console.log(user)
    setOrder(data);
    setCustomer(user);
    setLoading(false);
  };
  return (
    <>
       <div className="Main">
      
        <div className="navBar">
          <div className="logo">
            <img alt="logo" src="/assets/tamata_logo.svg" />
            <span>BNPL SHIPPING</span>
          </div>
          <div>
            <p>{currentUser.email}</p>
            <Button
              style="Logout"
              id="log-out"
              title="Log Out"
              onclick={() => handelLogout()}
            ></Button>
          </div>
        </div>
        <div>
          <div className="search_container">
            <div className="Search_box">
              <form className="Search_box" onSubmit={(e) => HandelSearch(e)}>
                <Input
                  style="input_search"
                  defaultValue={search}
                  onchange={(e) => setSearch(e)}
                  placeholder="Search By Order Id"
                />
                <img alt="logo" src="/assets/Search_icon.svg" />
              </form>
            </div>
          </div>
        </div>
        <div className="ship_table">
          <div className="table_content">
            <table>
              <thead className="ship_head">
                <tr>
                  <td>ORDER ID</td>
                  <td>CUTOMER NAME</td>
                  <td>PHONE</td>
                  <td>DATE</td>
                  <td>STATUS</td>
                  <td></td>
                </tr>
              </thead>
              <tbody className="ship_body">
                {order && (
                  <tr>
                    <td className="orderID">
                      <span>{order.orderId}</span>
                    </td>
                    <td>{customer?.name||"BNPL CUSTOMER"}</td>
                    <td>{customer?.phone}</td>
                    <td>{order?.orderDate}</td>
                    <td>
                      {order && order.ship?.status
                        ? `SHIP AT ${order.ship?.update}`
                        : "In Transit"}
                    </td>
                    <td>
                      <ReactToPrint
                        trigger={() => (
                          <button className="button ceb0028">Print</button>
                        )}
                        content={() => {
                          return Print();
                        }}
                      />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div id="Invoice_print" ref={refComp}>
          {order && customer && <ShippingPrint cus={customer} order={order} />}
        </div> 
      </div> 
    </>
  );
};
