import { Spinner } from "./Componets/Spinner";
import { Toast } from "./Componets/Toast";
import { AuthProvider } from "./Context/context";
import { Route, Routes } from "react-router-dom";
import { Login } from "./Pages/Login";
import { NotFound } from "./Componets/NotFound";
import { Home } from "./Pages/Home/Home";
import { Footer } from "./Pages/Home/Footer";
function App() {
  return (
    <>
      <AuthProvider>
        <Spinner />
        <Toast />
        <Routes>
          <Route path="/*" element={<Home />}/>
          <Route path="/login"  element={<Login />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        {/* <Footer/> */}
      </AuthProvider>
    </>
  );
}

export default App;
