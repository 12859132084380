import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { CurrencyIqFormat } from "../../../Componets/Input/CurrencyIqFormat ";
import {PermissionsGate} from "../../../Config/PermissionsGate";
import { AvailableBalance, dueNow } from "../../../Helpers/OrderStatus";
import {UserPermissions} from "../../../Helpers/Role";
import {UpdateUserInfo} from "./updateUserInfo";

export const UserInfo = ({ userData }) => {
  const[isOpen,setIsOpen]=useState(false);
  return (
    <>
      {userData && (
        <div className="user_detail">
          <div className="info-block">
            <h2 className="text-lg">{userData.name}</h2>
            {/* <span className="text-sm">{userData.employeeId}</span> */}
          </div>
          <PermissionsGate permission={UserPermissions.add_group}>
                    <div>
                    <span style={{color:"blue",borderBottom:"1px solid blue",fontWeight:"600",fontSize:"17px",cursor:"pointer"}} onClick={()=>setIsOpen(true)}>Edit</span>
                    </div>
                  </PermissionsGate>
          <div className="info-block">
          </div>
          <div className="info-block">
            <span className="text-sm">Email</span>
            <p className="text-md-info">{userData.email}</p>
          </div>
          <div className="info-block">
            <span className="text-sm">Phone Number</span>
            <p className="text-md-info">{userData.phone}</p>
          </div>
          <div className="info-block">
            <span className="text-sm">Available Allowance</span>
            <p className="text-md-info"><CurrencyIqFormat val={AvailableBalance(userData.allowance,userData.userOrders)||0}/></p>
          </div>

          <div className="info-block">
            <span className="text-sm">Total outStanding</span>
            <span className="text-md-info">
              <div>
                <CurrencyIqFormat
                  val={
                    userData.userOrders
                      ? userData.userOrders.reduce((a, b) => a + b.RestPrice, 0) ||
                        0
                      : 0
                  }
                />
              </div>
            </span>
          </div>

          <div className="info-block">
            <span className="text-sm">Total Due Now </span>
            <span className="text-md-info">
              <br />
              <CurrencyIqFormat val={dueNow(userData.userOrders)||0} />
            </span>
          </div>
        </div>
      )}
      {

       isOpen&& <UpdateUserInfo isOpen={isOpen} setIsOpen={setIsOpen} userData={userData}/>
      }
    </>
  );
};
