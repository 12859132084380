import { db } from "./Firebase-config";
import { collection } from "firebase/firestore";
export const usersCollection = collection(db, "users");
export const subscCollection = collection(db, "Subscriber");
export const groupCollection = collection(db, "Group");
export const CompaniesCollections = collection(db, "company");
export const CompanyRequest = collection(db, "CompanyRequest");
export const OrdersCollection=collection(db,"orders");
export const logsCollections=collection(db,"logs")




