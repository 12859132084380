import React, { useContext, useState } from "react";
import { PhotoProvider, PhotoConsumer } from "react-photo-view";
import { CurrencyIqFormat } from "../../../Componets/Input/CurrencyIqFormat ";
import { PermissionsGate } from "../../../Config/PermissionsGate";
import { UserPermissions } from "../../../Helpers/Role";
import { OrderDeatailModal } from "../../../Componets/Modal/OrderDeatailModal";
import { UploadInput } from "../../../Componets/Modal/UploadInput";
import { AiFillDelete } from "react-icons/ai";
import {deleteDocument, setDocumnet} from "../../../Config/middleWare";
import {logsCollections, OrdersCollection} from "../../../Config/firebase-collection";
import dayjs from "dayjs";
import {AuthContext} from "../../../Context/context";
import { v4 as uuidv4 } from "uuid";
export const OrderCard = ({ order }) => {
const {currentUser}=useContext(AuthContext)
  const [isOpen, setIsOpen] = useState(false);
  const DueNow = () =>
    order.payments
      .filter(
        (pay) =>
          new Date(pay.PaymentDate).getTime() < new Date().getTime() &&
          !pay.status
      )
      .reduce((a, b) => a + b.value || 0, 0);

  const totalPaid = () => order.orderAmount - order.RestPrice;
  const handelDelete=async()=>{
    let text=window.prompt("سبب االحذف");
   
 let res=   await deleteDocument(OrdersCollection,order.id);
 if(res){
  await setDocumnet(logsCollections, `${dayjs(new Date()).format("YYYY-MMss")}${uuidv4()}`, {
    createdBy: currentUser.name,
    type: "delete_order",
    createdAt: dayjs(new Date()).format("YYYY-MM-DD HH:mm"),
    desc: `order Id ${order.orderId} causes ${text}`,
    userId: currentUser.id,
  });
 }
  }
  return (
    <>
      <div className="order_card">
        <div>
          <div >
            <div style={{ display: "flex" ,alignItems:"center",justifyContent:"space-between"}}>
              <span>Order ID</span>
              <PermissionsGate permission={UserPermissions.delete_order}>
            <AiFillDelete fill="red"  size={"24px"} onClick={()=>handelDelete()}/> 

              </PermissionsGate>
            </div>
            <p>#{order.id}</p>
            <span>Order Created At {order.orderDate}</span>
          </div>
          <div>
            <p
              className={`${
                order.status == true ? "order-complete" : "order-pending"
              } `}
            >
              {order.status == true ? "Complete" : "Pending"}
            </p>
          </div>
        </div>

        <div>
          <span>Order Amount</span>
          <p>
            <CurrencyIqFormat val={order.orderAmount} />
          </p>
        </div>
        <div>
          <span>Order Outstanding</span>
          <p>
            <CurrencyIqFormat val={order.RestPrice} />
          </p>
        </div>
        <div>
          <span>Paid Amount</span>
          <p>
            <CurrencyIqFormat val={totalPaid() || 0} />
          </p>
        </div>
        <div>
          <span>Due Now</span>
          <p>
            <CurrencyIqFormat val={(order.status == false && DueNow()) || 0} />
          </p>
        </div>
        <div>
          {order.imageReceipt ? (
            <>
              <PhotoProvider>
                <PhotoConsumer
                  src={order.imageReceipt || "/assets/logo_ta.png"}
                >
                  <img
                    width="20"
                    height="20"
                    alt="logo"
                    src="/assets/logo_ta.png"
                  />
                </PhotoConsumer>
              </PhotoProvider>
            </>
          ) : (
            <PermissionsGate permission={UserPermissions.add_orders}>
              <UploadInput order={order} />
            </PermissionsGate>
          )}
        </div>
        <div onClick={() => setIsOpen(true)} className="open_order">
          <span className="open_button">Open</span>
        </div>

        {isOpen && (
          <OrderDeatailModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            order={order}
          />
        )}
      </div>
    </>
  );
};
