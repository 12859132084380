import React, { useContext } from "react";
import Modal from "react-modal";

import dayjs from "dayjs";
import { toast } from "react-toastify";
import { AuthContext } from "../../Context/context";
import { CurrencyIqFormat } from "../Input/CurrencyIqFormat ";
import { OrdersCollection } from "../../Config/firebase-collection";
import Button from "../Button/button";
import { useLocation } from "react-router";
import { PermissionsGate } from "../../Config/PermissionsGate";
import { Roles, UserPermissions } from "../../Helpers/Role";
import { updateDocument } from "../../Config/middleWare";

export const OrderDeatailModal = ({ isOpen, setIsOpen, order }) => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      height: "700px",
      width: "70%",
    },
  };
  const { state } = useLocation();
  const { setLoading, currentUser } = useContext(AuthContext);
  const DueNow = () => {
    return order.payments
      .filter(
        (r) =>
          new Date(r.PaymentDate).getTime() < new Date().getTime() && !r.status
      )
      .reduce((a, b) => a + b.value, 0);
  };
  const totalPaid = () => order.orderAmount - order.RestPrice;

  const PaymentsDetail = (order, RestPrice) => {
    var pendingPayment = order.payments.filter((e) => !e.status);
    var paymentsLength = order.payments.length;
    for (var i = 0; i < paymentsLength; i++) {
      if (!order.payments[i].status) {
        if (i === paymentsLength - 1) {
          let x = pendingPayment.at(-1);
          order.payments[i].value =
            RestPrice -
            (pendingPayment.reduce(
              (a, b) => a + (b.collectValue || b.value),
              0
            ) -
              x.value);
        } else {
          order.payments[i].value =
            Math.floor(RestPrice / 1000 / pendingPayment.length) * 1000;
        }
      }
    }
    return order.payments;
  };

  const CollectPayment = async (pay) => {
    var paymentAmount = Number(window.prompt("Payment Amount"));
    if (paymentAmount>=pay.value&&!isNaN(paymentAmount)&&order.RestPrice>0) {
      setLoading(true);
      try {
        var paymentIndex = order.payments.findIndex(
          (e) => e.increment == pay.increment
        );

        order.RestPrice -= Number(paymentAmount);
        order.payments[paymentIndex] = {
          ...order.payments[paymentIndex],
          status: true,
          value: Number(paymentAmount),
          collectValue: Number(paymentAmount),
          collectDate: dayjs(new Date()).format("YYYY-MM-DD"),
        }; 
        if (order.RestPrice == 0) {
          order.status = true;
          order.payments = order.payments.slice(0, paymentIndex + 1);
        } else {
          order.payments = PaymentsDetail(order, order.RestPrice);
        }
        await updateDocument(OrdersCollection, order.id, {
          payments: order.payments,
          RestPrice: order.RestPrice,
          status: order.status,

        });
 
        toast.success("Payment has been received");
        setLoading(false);
      } catch (error) {
        toast.error("server Error Try Again");
        setLoading(false); 
      }
    }else{
      toast.error("حدث خطا تاكد من معلومات بشكل صحيح")
    }

    // setIsOpen(false);
  };
  const handeReceived = async (index) => {
    let payments = order.payments;
    payments[1].receivedStatus = "Received";

    await updateDocument(OrdersCollection, order.orderId, {
      payments: [...payments],
    });
  };
var lastPaymentCollect=order.payments.findIndex(e=>!e.status);
//add 
  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      ariaHideApp={false}
      contentLabel="Example Modal"
    >
      <div className="Modal_header">
        <div className="order_information">
          <div className="order_info">
            <span>Order ID</span>
            <div className="order_id">#{order.id}</div>
            <span> Order Created At {order.orderDate}</span>
          </div>
        </div>
        <img
          onClick={() => setIsOpen((r) => !r)}
          width="20"
          height="30"
          alt="close"
          src="/assets/close_modal.svg"
          style={{ cursor: "pointer" }}
        />
      </div>
      <div>
        <div className="order_modal_info">
          <div>
            <span>Order Amount</span>
            <p>
              <CurrencyIqFormat val={order.orderAmount} />
            </p>
          </div>
          <div>
            <span>Total Paid</span>
            <p>
              <CurrencyIqFormat val={totalPaid() || 0} />
            </p>
          </div>
          <div>
            <span>Order Outstanding</span>
            <p>
              <CurrencyIqFormat val={order.RestPrice} />
            </p>
          </div>
          <div>
            <span>Due Now</span>
            <p>
              <CurrencyIqFormat val={DueNow() || 0} />
            </p>
          </div>
          <div>
            <span>Init paid</span>
            <p>
              <CurrencyIqFormat val={order.initPayment.collectValue || 0} />
            </p>
          </div>
        </div>
        <div className="table_container full_hw">
          <div className="thead">
            <span className="small_width">#</span>
            <span>Payment Date</span>
            <span>Required value</span>
            <span>Status</span>
            <span>Amount Paid</span>
            <span>Paid At</span>
            <span></span>
            <span></span>
          </div>
          <div className="row_odd">
            {order.payments.length != 0 ? (
              order.payments.map((e, index) => (
                <div className="body_container hover_effect" key={index * 4}>
                  <span className="small_width">{index + 1}</span>
                  <span>{e.PaymentDate}</span>
                  <span>
                    {
                      <CurrencyIqFormat
                        val={order.status == true && !e.status ? 0 : e.value}
                      />
                    }
                  </span>
                  <span
                    className={`${
                      e.status || order.status == true
                        ? "status-ontime"
                        : new Date(e.PaymentDate).getTime() <
                            new Date().getTime() && !e.status
                        ? "status-late"
                        : "status-upcoming"
                    }`}
                  >
                    {e.status || order.status
                      ? "On Time"
                      : new Date(e.PaymentDate).getTime() <
                          new Date().getTime() && !e.status
                      ? "Late"
                      : `${e.receivedStatus || "UPCOMMING"}`}
                  </span>
                  <span>
                    {e.status || order.status == true ? (
                      <>
                        <CurrencyIqFormat val={e.collectValue} />
                      </>
                    ) : (
                      "Not Collected"
                    )}
                  </span>
                  <span>
                    {(e.status || order.status == true) && e.collectDate}
                  </span>

                  <span>
                    <PermissionsGate permission={UserPermissions.add_payments}>
                      {new Date().getTime() >
                        new Date(e.PaymentDate).getTime() &&
                        !e.status &&
                        order.status !== true  && lastPaymentCollect==index && (
                          <Button
                            color="c70b44d"
                            style="collect-pay"
                            title="collect Payment"
                            // onclick={() => CollectPayment(e)}
                          />
                        )}
                      <></>
                    </PermissionsGate>
                  </span>
                  <span>
                    <PermissionsGate permission={UserPermissions.add_users}>
                      {e.status == false &&
                        e.receivedStatus !== "Received" &&
                        new Date().getTime() >
                          new Date(e.PaymentDate).getTime() && (
                          <button
                            className="receivedBtn"
                            // onClick={() => handeReceived(index)}
                          >
                            Received Payment
                          </button>
                        )}
                    </PermissionsGate>
                  </span>
                </div>
              ))
            ) : (
              <div>NO Payment</div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};
