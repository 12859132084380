import React from 'react'
    
export const CurrencyIqFormat = ({val=0}) => {
    return (
        new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'IQD',
            currencySign: 'accounting',


          }).format(val)
    )
}