import React,{ useContext }  from "react";
import { ScaleLoader ,PulseLoader
} from "react-spinners";
import { AuthContext } from "../Context/context";

export const Spinner = () => {
    const{ loading }=useContext(AuthContext);
    return (
        <span>
            {loading && (
                <div className="spinnerDiv">
                    <PulseLoader

                        className="spinnerDiv"
                        sizeUnit={"px"}
                        size={25}
                        margin={8}
                        color={"#eb0028"}
                        loading={loading}
                    />
                </div>
            )}
        </span>
    );
};