export const Roles = {
  Admin: "admin",
  SuperVisor: "supervisor",
  Ship: "shipping",
  Team:"team"
};

export const UserPermissions = {
  import_data: "import_data",
  add_group: "add_group",
  add_users: "add_users",
  add_orders: "add_orders",
  add_teams: "add_teams",
  add_payments: "add_payments",
  manage_vendor:"manage_vendor",
  admin:"admin",
  delete_order:"delete_order"
};

