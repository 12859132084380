import React, { useContext, useState } from "react";
import Button from "../Componets/Button/button";
import Input from "../Componets/Input/Input";
import { toast } from "react-toastify";
import { auth } from "../Config/Firebase-config";
import { AuthContext } from "../Context/context";
import { useLocation, useNavigate } from "react-router";
import { getSingleDoc } from "../Config/middleWare";
import { usersCollection } from "../Config/firebase-collection";
export const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setLoading, currentUser } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handelLogin = async (e) => {
    e.preventDefault();
    if (!password || !email) return;
    setLoading(true);
    auth
      .signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        getSingleDoc(usersCollection, userCredential.user.uid).then((e) => {
          if (!e.auth) {
            toast.error("Account Disable");
            return;
          }
          setLoading(false);
          switch (e.role) {
            case "shipping":
              return navigate("/Operattion");
            default:
              return navigate("/");
          }
        });
      })
      .catch((error) => {
        toast.error("Invalid Login");
        setLoading(false);
      });
  };

  return (
    <>
      <div className="login-container">
        <div className="login-left-side">
          <h1 className="login-headding">Login</h1>
          <p className="login-text">
            Start using the app and track your order and cash flow
          </p>
          <div className="login-form" id="login-from">
            <form>
              <Input
                id="login-form-email"
                style="input"
                placeholder="Email"
                type="email"
                value={email}
                onchange={(e) => setEmail(e)}
              ></Input>
              <Input
                id="login-form-password"
                style="input"
                placeholder="Password"
                type="password"
                onchange={(e) => setPassword(e)}
                autocomplete={"on"}
                value={password}
              ></Input>

              <Button
                color="ceb0028"
                style="button"
                id="login-form-submit"
                title="Login"
                onclick={(e) => handelLogin(e)}
              ></Button>
            </form>
          </div>
        </div>
        <div className="logo">
          <img
            width="100"
            height="20"
            alt="logo"
            src="/assets/tamata_logo.svg"
          />
        </div>
      </div>
    </>
  );
};
