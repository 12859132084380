export const  updateMagentStatus=async(email,status,customerID)=>{
    var raw = JSON.stringify({
        email: email,
        isBnpl: status,
        bnplCustomerId:customerID,
      });
    var requestOptions = {
        method: "POST",
        headers: {
          TOKEN: "533b38d7a41f551f4111283c10102c4418cdf",
          "Content-Type": "application/json",
        },
        body: raw,
        redirect: "follow",
      };
      const response = await fetch(
        "https://bnpl-api.herokuapp.com/tamata_bnpl/updateStatus",
        requestOptions
      );
      const myRes = await response.json();
      return myRes;
}